import {RelatedLink} from "../utils";

export const enRelatedLinks: RelatedLink[] = [
	{ link: "59Mortality.pdf", name: "Older Child Mortality Rate (5-9 years)", description: "The older child mortality rate is measured as the probability that a child aged 5 years dies before reaching his or her 10th birthday (5q5)." },
	{ link: "ARI CareSeeking.pdf", name: "Care Seeking for Acute Respiratory Infection", description: "Percentage of children under 5 years of age with suspected pneumonia (cough and difficult breathing NOT due to a problem from a blocked nose) in the two weeks taken to an appropriate health facility or provider" },
	{ link: "Care Seeking Fever.pdf", name: "Care Seeking Fever", description: "Percentage of children under 5 years of age with fever in the last two weeks taken to an appropriate health facility or provider." },
	{ link: "Complementary Feeding.pdf", name: "Complementary Feeding", description: "The proportion of children aged 6–23 months who had at least the minimum dietary diversity and the minimum meal frequency during the previous day." },
	{ link: "Developmentally On Track.pdf", name: "Developmentally On Track", description: "The percentage of children under 5 years of age who are developme ntally on track in health, learning and psychosocial well-being is currently being measured by the percentage of children aged 36-59 months who are developmentally on-track in at least three of the following four domains: literacy-numeracy, physical, socio-emotional and learning." },
	{ link: "Diarrhoea Treatment.pdf", name: "Diarrhoea Treatment", description: "Percentage of children under 5 years of age with diarrhoea in the last two weeks receiving ORS (fluids made from ORS packets or pre -packaged ORS fluids) and zinc supplement" },
	{ link: "Exclusive Breastfeeding.pdf", name: "Exclusive Breastfeeding", description: "Proportion of children 0-5 months of age who are exclusively fed by breast milk" },
	{ link: "Immunization Measles.pdf", name: "Measles Vaccination", description: "Percentage of children aged 12-23 months immunized with measles containing vaccine (MCV2)" },
	{ link: "Immunization.pdf", name: "Full Vaccination Coverage", description: "Proportion of the target population covered by all vaccines included in the national schedule by vaccine.",  },
	{ link: "Malaria Diagnostic.pdf", name: "Malaria Diagnostic", description: "Percentage of children presenting with fever tested for malaria in endemic countries" },
	{ link: "Malaria First Line Treatment.pdf", name: "Malaria First Line Treatment", description: "Percentage of confirmed under-5 malaria cases treated with ACT" },
	{ link: "Malaria ITN.pdf", name: "Use of insecticide treated bednets (ITNs)", description: "Percentage of children under-5 years of age sleeping under an insecticide treated net (ITN) the previous night." },
	{ link: "Maltreatment.pdf", name: "Maltreatment", description: "Proportion of children aged 1-17 years who experienced any physical punishment and/or psychological aggression by caregivers in the past month." },
	{ link: "Overweight.pdf", name: "Percentage Overweight", description: "Percentage of children 0 to 59 months who are overweight (weight for height greater than 2 standard deviations from the median of the WHO Child Growth Standards)." },
	{ link: "Road Traffic Injury.pdf", name: "Road Traffic Injury", description: "Death rate attributable to road traffic injury" },
	{ link: "Stunting.pdf", name: "Percentage Stunting", description: "Percentage of stunted (moderate and severe) children aged 0–59 months (moderate = height-for-age below -2 standard deviations from the WHO Child Growth Standards median; severe = height-for-age below -3 standard deviations from the WHO Child Growth Standards median)." },
	{ link: "U5Mortality.pdf", name: "Under-5 Mortality Rate", description: "The under-five mortality rate is measured as the probability per 1,000 that a newborn baby will die before reaching age five, if subject to age-specific mortality rates of the specified year, expressed per 1,000 live births." },
	{ link: "Vitamin A.pdf", name: "Vitamin A Dosing", description: "Percentage of children aged 6–59 months who received two age-appropriate doses of vitamin A in the past 12 months." },
	{ link: "Wasting.pdf", name: "Percentage with Wasting", description: "Percentage of wasted (moderate and severe) children aged 0 to 59 months (moderate= weight for height less than 2 standard deviations from the median of the WHO Child Growth Standards; severe = weight for height less than 3 standard deviations from the median of the WHO Child Growth Standards)." },
];
