import {enText} from "./en.text";
import {frText} from "./fr.text";
import {esText} from "./es.text";
import {enRelatedLinks} from "./related-links/en.relatedLinks";
import {esRelatedLinks} from "./related-links/es.relatedLinks";
import {frRelatedLinks} from "./related-links/fr.relatedLinks";

export type LanguageType = 'en' | 'fr' | 'es';

const languages: Record<LanguageType, Record<string, string>> = {
    en: enText,
    fr: frText,
    es: esText,
}

export const i18n = (language: LanguageType = "en", key: string)=> {
    if(language) {
        return languages[language][key];
    }
    return languages["en"][key];
}

export interface RelatedLink {
    name: string,
    description: string,
    link: string
}

export const relatedLinks: Record<LanguageType, RelatedLink[]> = {
    en: enRelatedLinks,
    es: esRelatedLinks,
    fr: frRelatedLinks,
}

export const databaseLinks: Record<LanguageType, string> = {
    en: "CHAT_indicator_database_May_20.xlsx",
    es: "CHAT_indicator_database_May_20.xlsx",
    fr: "CHAT_indicator_database_May_20.xlsx",
}

export const caseStudiesCombinedFiles: Record<LanguageType, string> = {
    en: "MoNITOR_case studies_validation_01 Dec_2020.pdf",
    es: "related-sheets/es/Case_Studies_Spanish.pdf",
    fr: "related-sheets/fr/MoNITOR_Etudes des Cas.pdf",
}
