import React from 'react';
import './Header.css';

import chatIllustration from '../assets/kidsrun-cropped-2.jpg';
import { Container } from 'react-bootstrap';
import {i18n, LanguageType} from '../i18n/utils';

export default class Header extends React.Component<{language: LanguageType}, any> {

	componentDidMount() {
		(window as any).ga.pageview(window.location.pathname, window.location, "Initial Page");
	}

	private i18n (key: string): string {
		return i18n(this.props.language, key);
	}

    render(){
		return (
				<div className="Header">
					<Container>
						<div className="intro-section">

							<p>
								Improving health and well-being outcomes for children (ages 1 month to 59 months, and 5 to 9
								years) is a priority for WHO and UNICEF. CHAT is a technical advisory group to WHO and UNICEF.
								Its main objective is to support the harmonization and standardization of child health and well-
								being indicators.
							</p>
							<br/>
							<p>{"The CHAT online Toolkit includes:"}
								<ul>
									<li>
										{"A searchable indicator database which contains key indicators on child health and well-being."}
									</li>
									<li>
										{"Indicator reference sheets for all indicators."}
									</li>
								</ul>
							</p>
						</div>

						<img
							src={chatIllustration}
							width="380"
							className="mother-img"
							alt="Monitor Illustration"
						/>
					</Container>
				</div>
		);
	}
};
