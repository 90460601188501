import React from 'react';
import {Container as ContainerBS, Navbar as NavbarBS} from 'react-bootstrap';

import englishLogo from '../assets/WHO-EN-C-H.png';
import frenchLogo from '../assets/WHO-FR-C-H.png';
import spanishLogo from '../assets/WHO-SP-C-H.png';
import unicef from '../assets/UNICEF.png';

import './Navbar.css';
import {LanguageType} from "../i18n/utils";

const blueLogos = {
	en: englishLogo,
	fr: frenchLogo,
	es: spanishLogo,
}

interface NavbarProps {
	sticky:boolean;
	language: LanguageType;
	selectLanguage: (language: LanguageType) => void;
}

// const languageTexts: Record<string, any> = {
// 	en: "English",
// 	fr: "Français",
// 	es: "Español",
// };

export default class Navbar extends React.Component<NavbarProps, any> {

	render(){
		return (
			<NavbarBS className={this.props.sticky ? 'sticky-bar' : ''}>
				<ContainerBS>
					<NavbarBS.Brand href="/#home">
						<h1 className={"chat"}>CHAT</h1>
					</NavbarBS.Brand>
					<NavbarBS.Brand href="/#">
						<img
							src={unicef}
							width="170"
							className="d-inline-block align-top"
							alt="Monitor Logo"
						/>
					</NavbarBS.Brand>
					<NavbarBS.Brand href="/#">
						<img
							src={blueLogos[this.props.language]}
							width="170"
							height={"44"}
							className="d-inline-block align-top"
							alt="WHO"
						/>
					</NavbarBS.Brand>

					{/*<Dropdown*/}
					{/*	className={'navbar-dropdown'}*/}
					{/*	onSelect={(eventKey: string | null) => {*/}
					{/*		this.props.selectLanguage(eventKey as LanguageType);*/}
					{/*	}}>*/}
					{/*	<Dropdown.Toggle variant="outline-dark" id="dropdown-flags" className="navbar-dropdown-flags-select">*/}
					{/*		{languageTexts[this.props.language]}*/}
					{/*	</Dropdown.Toggle>*/}

					{/*	<Dropdown.Menu>*/}
					{/*		<Dropdown.Item eventKey={"en"}>{languageTexts["en"]}</Dropdown.Item>*/}
					{/*		<Dropdown.Item eventKey={"fr"}>{languageTexts["fr"]}</Dropdown.Item>*/}
					{/*		<Dropdown.Item eventKey={"es"}>{languageTexts["es"]}</Dropdown.Item>*/}
					{/*	</Dropdown.Menu>*/}
					{/*</Dropdown>*/}

				</ContainerBS>
			</NavbarBS>
		);
	}
}
