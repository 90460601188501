export const esText = {
    "Mother and Newborn Information": "Información sobre la madre y el recién nacido",
    "for Tracking Outcomes and Results": "para el seguimiento de los productos y resultados",
    "(MONITOR) - ": "(MoNITOR) -",
    "online indicator Toolkit": "Conjunto de instrumentos en línea sobre los indicadores",
    "MONITOR is technical advisory group to WHO on measurement, metrics and monitoring of maternal and newborn health co-convened by the Departments of Maternal, Newborn, Child and Adolescent Health (MCA) and Sexual and Reproductive Health and Research (SRH).": "MoNITOR es un grupo de asesoramiento técnico de la OMS en materia de medición, cuantificación y\nseguimiento de la salud de la madre y el recién nacido, coordinado conjuntamente por los\nDepartamentos de Salud de la Madre, el Recién Nacido, el Niño y el Adolescente (MCA) y de Salud\nSexual y Reproductiva e Investigaciones Conexas (SRH).",
    " See more ": "Ver más",
    "The MoNITOR online Toolkit includes:": "El conjunto de instrumentos en línea de MoNITOR incluye:",
    "A searchable indicator database which contain 167 indicators related to maternal and newborn health, including core, optional and aspirational indicators": "una base de datos en la que pueden realizarse búsquedas de 167 indicadores relacionados con\nla salud de la madre y el recién nacido, incluidos indicadores básicos, opcionales y\naspiracionales;",
    "Indicator metadata reference sheets for 25 core indicators": "hojas de referencia de metadatos para los 25 indicadores básicos.",
    "Recommendations and case studies on indicator testing and validation": "Recomendaciones y estudios de caso sobre las pruebas y la validación de los indicadores",
    "Reference Sheets": "Hojas de referencia",
    "Download Case Studies": "Descargar estudios de caso",
    "Export Database": "Exportar base de datos",
    "Search for an indicator:": "Buscar un indicador",
    "Indicator Name:": "Nombre del indicador",
    "Enter topic": "Ingrese el tema",
    "Indicator type:": "Tipo de indicador",
    "Not selected": "No seleccionado",
    "Input": "Insumo",
    "Output": "Producto",
    "Outcome": "Resultado",
    "Impact": "Impacto",
    "Domain:": "Ámbito:",
    "Fertility": "Fecundidad",
    "Financing": "Financiación",
    "Governance": "Gobernanza",
    "Health Information": "Información sanitaria",
    "Health work force": "Personal sanitario",
    "Mortality": "Mortalidad",
    "Other health status": "Otro estado de salud",
    "Risk factors and behaviours": "Factores de riesgo y comportamientos",
    "Service access and availability": "Acceso y disponibilidad de los servicios",
    "Service coverage": "Cobertura del servicio",
    "Service quality and safety": "Seguridad y calidad del servicio",
    "Supply chain": "Cadena de suministro",
    "All": "Todo",
    "Intrapartum": "Intraparto",
    "Antenatal": "prenatal",
    "Antenatal Postnatal": "Postnatal prenatal",
    "Antenatal intrapartum": "Intraparto prenatal",
    "Intrapartum Postnatal": "Intraparto posnatal",
    "Postnatal": "Posnatal",
    "CRVS": "CEV",
    "National surveillance system": "Sistema de vigilancia nacional",
    "HH Survey": "Encuesta de Hogares",
    "Health facility assessment": "Evaluación de instalaciones de salud",
    "HMIS/ DHIS2": "Sistema de información de gestión sanitaria / DHIS2",
    "Other": "Otra",
    "Clear Filters": "Limpiar filtros",
    "Indicator level for interest and use:": "Nivel del indicador en cuanto a su interés y uso:",
    "Global": "Mundial",
    "National": "Nacional",
    "Sub-National": "Subnacional",
    "Indicator": "Tipo",
    "type": "de indicador",
    "Measurement": "Nivel",
    "level": "de medición",
    "Indicator Name": "Nombre del indicador",
    "Domain": "Ámbito",
    "Continuum": "Continuidad",
    "of care": "de la atención",
    "Status": "Estado",
    "Quality": "Calidad",
    "of Care": "de la atención",
    "Showing": "Affichage",
    "results": "des résultats",
    "Back to indicators listing": "Volver al listado de indicadores",
    "Measurement level": "Nivel de medición",
    "Indicator type": "Tipo de indicador",
    "Indicator level": "Nivel indicador",
    "Numerator:": "Numerador:",
    "Denominator:": "Denominador:",
    "Continuum of care:": "Continuidad de la atención:",
    "Use:": "Usar:",
    "Quality of care:": "Calidad  de la atención",
    "Status:": "Estado:",
    "Core 100:": "Núcleo 100:",
    "Adolescent health indicators list:": "Lista de indicadores de salud de los adolescentes:",
    "60 global strategy:": "60 estrategia global:",
    "QED MNH Common Ind:": "Indicadores comunes de equidad, dignidad, calidad de salud de la madre y el recién nacido",
    "EPMM:": "Iniciativa para poner fin a la mortalidad materna prevenible",
    "ENAP:": "Plan de acción «Todos los recién nacidos»",
    "Countdown to 2030:": "Cuenta regresiva para 2030",
    "SDGs:": "ODS",
    "The UN Commission on Life-Saving Commodities for Women and Child (UNCoLSC) indicator:": "Indicador de la Comisión de las Naciones Unidas sobre productos básicos que salvan vidas para mujeres y niños (UNCoLSC):",
    "WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Core Indicator": "OMS / UNICEF RMNCAH HMIS Análisis de datos y uso del kit de herramientas Indicador básico",
    "WHO/UNICEF RMNCAH HMIS Data Analysis and Use Toolkit Additional Indicator": "Indicador adicional del kit de herramientas de uso y análisis de datos de la OMS / UNICEF RMNCAH HMIS",
    "ANC monitoring framework": "Marco de seguimiento de la atención prenatal",
    "Operational Notes:": "Notas operativas:",
    "Back to indicator view": "Volver a la vista de indicadores",
    "Download sheet": "Descargar hoja",
    "Privacy Policy": "Política de privacidad",
    "About us": "Sobre nosotros",
    "WHO Copyright": "Derecho de autor de la OMS",
    "Feasible Data Sources:": "Fuentes de datos factibles:"
}
