import React from 'react';
import './Filter.css';
import {Col, Container, Form, Row} from 'react-bootstrap';
import { databaseLinks, i18n, LanguageType } from '../i18n/utils';
import { Autocomplete } from '../autocomplete/Autocomplete';
import algoliasearch from 'algoliasearch';
import { InstantSearch } from 'react-instantsearch-hooks';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';

interface FilterState {
	name: string;
	type: string;
	domain: string;
	continuum_of_care: string;
	feasible_data_sources: string;
	indicator_level_for_interest_and_use: string;
}

interface FilterProps {
	onFilter: () => void;
	savedFilters: FilterState;
	language: LanguageType;
}

enum FieldType{
	name,
	type,
	domain,
	continuum_of_care,
	feasible_data_sources,
	indicator_level_for_interest_and_use,
}

const searchClient = algoliasearch(
		'EBEW60JBYX',
		'f7dd9c908e9a7729087288e3a82c8b3c'
);

function debouncePromise(fn: any, time: any) {
  let timerId: NodeJS.Timeout | undefined = undefined;

  return function debounced(...args: any[]) {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time);
    }) ;
  };
}

const debounced = debouncePromise((items: any) => Promise.resolve(items), 1000) as any;

export default class Filter extends React.Component<FilterProps, FilterState> {

	handleIndicatorLevelForInterestAndUseChange(value: any) {
		this.props.savedFilters.indicator_level_for_interest_and_use = value;
		this.props.onFilter();
	}

	saveChange(event: any, type: FieldType) {
		// TODO: Switch to non-reference base filter change
		switch(type){
			case FieldType.name:
				this.props.savedFilters.name = event.target.value;
			break;
			case FieldType.type:
				this.props.savedFilters.type = event.target.value;
			break;
			case FieldType.domain:
				this.props.savedFilters.domain = event.target.value;
			break;
			case FieldType.continuum_of_care:
				this.props.savedFilters.continuum_of_care = event.target.value;
			break;
			case FieldType.feasible_data_sources:
				this.props.savedFilters.feasible_data_sources = event.target.value;
			break;
			case FieldType.indicator_level_for_interest_and_use:
				this.props.savedFilters.indicator_level_for_interest_and_use = event.target.value;
			break;
		}

		this.props.onFilter();
	}

	clearFilters() {
		this.props.savedFilters.name = '';
		this.props.savedFilters.type = '';
		this.props.savedFilters.domain = '';
		this.props.savedFilters.continuum_of_care = '';
		this.props.savedFilters.feasible_data_sources = '';

		this.props.onFilter();
	}

	private i18n (key: string): string {
		return i18n(this.props.language, key);
	}

	render() {
		return (
		  	<div className="Filter">
				<Container className="filter-fields">
					<div className="d-flex justify-content-end mr-2 mt-3 documents-links">
						<a href="/related-links" className="btn btn-outline-primary ml-1 mr-1">
							<i className="far fa-folder-open"/> {this.i18n("Reference Sheets")}
						</a>
						<a href={`${process.env.PUBLIC_URL}/${databaseLinks[this.props.language]}`}
						   download
						   className="btn btn-outline-primary ml-1 mr-1">
							<i className="far fa-file-excel"/> {this.i18n("Export Database")}
						</a>
					</div>

					<h3 className="search-panel-label">{this.i18n("Search for an indicator:")}</h3>
					<Form className="search-for-project-form">
						<Form.Group as={Row}>
							<Form.Group as={Col} controlId="formGridEmail">
								<Form.Label>{this.i18n("Indicator Name:")}</Form.Label>
									{/* <Form.Control onChange={(e:any) => this.saveChange(e, FieldType.name)}
											  placeholder={this.i18n("Enter topic")}
									value={this.props.savedFilters.name} /> */}

									<InstantSearch indexName="index_chat" searchClient={searchClient}>

										<Autocomplete 
											openOnFocus={true}
											initialState={{query: this.props.savedFilters.name}}
											onStateChange={({setQuery, state}) => {
												setQuery(state.query);
												this.props.savedFilters.name=state.query;
												this.props.onFilter();
											}}
											onReset={({setQuery}) => {
												setQuery('');
												this.props.savedFilters.name='';
												this.props.onFilter();
											}}
											getSources = {({query, setQuery}) => {
													return debounced([
														{
															sourceId:'index_chat',
															getItems: () => {
																return getAlgoliaResults({
																		searchClient,
																		queries: [
																			{
																				indexName: 'index_chat',
																				query,
																			},
																		],

																});
															},
															templates: {
																item: ({item, components}: any) => {
																	return(																				
																		<a href={item.url as any} className="aa-ItemLink">

																			<div className="aa-ItemContent">
																				<div className="aa-ItemTitle"
																					onClick={() => {
																						/*https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/state/#manually-updating-the-state*/ 
																						setQuery(item.name as string)
																					}}>
																					<components.Highlight hit={item} attribute="name" />
																				</div>
																			</div>
																		</a>
																	)
																}
															},
														}
													])
											}}
										/>
									</InstantSearch>

							</Form.Group>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Group as={Col} controlId="formGridPassword">
								<Form.Label>{this.i18n("Indicator type:")}</Form.Label>
								<Form.Control onChange={(e:any) => this.saveChange(e, FieldType.type)} as="select" value={this.props.savedFilters.type}>
									<option value="">{this.i18n("Not selected")}</option>
									<option value={this.i18n("Input")}>{this.i18n("Input")}</option>
									<option value={this.i18n("Output")}>{this.i18n("Output")}</option>
									<option value={this.i18n("Outcome")}>{this.i18n("Outcome")}</option>
									<option value={this.i18n("Impact")}>{this.i18n("Impact")}</option>
								</Form.Control>
							</Form.Group>

							<Form.Group as={Col} controlId="formGridPassword">
								<Form.Label>{this.i18n("Domain:")}</Form.Label>
								<Form.Control onChange={(e:any) => this.saveChange(e, FieldType.domain)} as="select" value={this.props.savedFilters.domain}>
									<option value="">{this.i18n("Not selected")}</option>
									{/*<option value={this.i18n("Fertility")}>{this.i18n("Fertility")}</option>*/}
									<option value="Mortality">Mortality</option>
									<option value="Chronic Condition and Disability">Chronic Condition and Disability</option>
									<option value="Injury">Injury</option>
									<option value="Nutrition and Growth">Nutrition and Growth</option>
									<option value="Learning and Responsive Care-giving">Learning and Responsive Care-giving</option>
									<option value="Immunization">Immunization</option>
									<option value="Childhood Illnesses">Childhood Illnesses</option>
									<option value="Safety and Security">Safety and Security</option>
									<option value="Malaria">Malaria</option>
								</Form.Control>
							</Form.Group>

							<Form.Group as={Col} controlId="formGridPassword">
								<Form.Label>{this.i18n("Continuum of care:")}</Form.Label>
								<Form.Control onChange={(e:any) => this.saveChange(e, FieldType.continuum_of_care)} as="select" value={this.props.savedFilters.continuum_of_care}>
									<option value="">{this.i18n("Not selected")}</option>
									<option value="1 month to 5 years">1 month to 5 years</option>
									<option value="1 month to 9 years">1 month to 9 years</option>
									<option value="5 - 9 years">5 - 9 years</option>
								</Form.Control>
							</Form.Group>

							<Form.Group as={Col} controlId="formGridPassword">
								<Form.Label>{this.i18n("Feasible Data Sources:")}</Form.Label>
								<Form.Control onChange={(e:any) => this.saveChange(e, FieldType.feasible_data_sources)} as="select" value={this.props.savedFilters.feasible_data_sources}>
									<option value="">{this.i18n("Not selected")}</option>
									<option value="crvs">{this.i18n("CRVS")}</option>
									<option value="national_surveillance_system">{this.i18n("National surveillance system")}</option>
									<option value="hh_survey">{this.i18n("HH Survey")}</option>
									<option value="health_facility_assessment">{this.i18n("Health facility assessment")}</option>
									<option value="hmis_dhis2">{this.i18n("HMIS/ DHIS2")}</option>
									<option value="other">{this.i18n("Other")}</option>
								</Form.Control>
							</Form.Group>
						</Form.Group>
						<div className="clear-filters-wrapper">
							<span onClick={this.clearFilters.bind(this)}
								  className="clear-filters btn btn-warning">
								{this.i18n("Clear Filters")}
							</span>
						</div>
					</Form>
				</Container>

				<Container>

					<div className="sort-by-basic-panel">
						<div className="d-flex justify-content-between">
							<p>{this.i18n("Indicator level for interest and use:")}</p>

							<ul className="list-group list-group-horizontal">
								<li className={`list-group-item ${
									!this.props.savedFilters.indicator_level_for_interest_and_use && 'active'
								}`}
									onClick={()=>this.handleIndicatorLevelForInterestAndUseChange(undefined)}>
									{this.i18n("All")}
								</li>
								<li className={`list-group-item ${
									this.props.savedFilters.indicator_level_for_interest_and_use === this.i18n("Global") && 'active'
								}`}
									onClick={()=>this.handleIndicatorLevelForInterestAndUseChange(this.i18n("Global"))}>
									{this.i18n("Global")}
								</li>
								<li className={`list-group-item ${
									this.props.savedFilters.indicator_level_for_interest_and_use === this.i18n("National") && 'active'
								}`}
									onClick={()=>this.handleIndicatorLevelForInterestAndUseChange(this.i18n("National"))}>
									{this.i18n("National")}
								</li>
								<li className={`list-group-item ${
									this.props.savedFilters.indicator_level_for_interest_and_use === this.i18n("Sub-National") && 'active'
								}`}
									onClick={()=>this.handleIndicatorLevelForInterestAndUseChange(this.i18n("Sub-National"))}>
									{this.i18n("Sub-National")}
								</li>
							</ul>
						</div>
					</div>
				</Container>
			</div>
		);
	}
}

