import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './navbar/Navbar';
import Header from './header/Header';
import Filter from './filter/Filter';
import Results from './results/Results';
import Footer from './footer/Footer';
import RelatedLinksView from './view-listing/RelatedLinksView';
import ViewProject from './view-project/ViewProject';
import { Indicator } from "./indicator";
import { indicatorEndpoint } from "./endpoints";
import { Container as ContainerBS, Navbar as NavbarBS } from "react-bootstrap";
import {i18n, LanguageType} from "./i18n/utils";

const pageSize = 10;

interface AppState {
	language: LanguageType;
	allIndicators: Indicator[] | undefined;
	indicators: Indicator[] | undefined;
	pageCount: number;
	active: number;
	filters: {
		name: string;
		type: string;
		domain: string;
		continuum_of_care: string;
		feasible_data_sources: string;
		indicator_level_for_interest_and_use: string;
		sort: string;
	};
}

class App extends React.Component<any, AppState> {

	constructor(props: any) {
		super(props);
		// TODO: Reset these on language change
		const language = sessionStorage.getItem("language") as LanguageType || "en";
		this.state = {
			language,
			indicators: undefined,
			allIndicators: undefined,
			pageCount: 0,
			active: 1,
			filters: {
				name: '',
				type: '',
				domain: '',
				continuum_of_care: '',
				feasible_data_sources: '',
				indicator_level_for_interest_and_use: '',
				sort: ''
			}
		};

		// Onload prepare all indicators:
		fetch(`${indicatorEndpoint}?language=${language}`)
			.then((data)=>data.json())
			.then((allIndicators)=>{
				this.setState({
					allIndicators,
					pageCount: this.getPageCount(allIndicators),
					indicators: allIndicators.slice(0, pageSize),
					active: 1,
				});
			});
	}

	filter() {
		const url = new URL(indicatorEndpoint);
		const copyOfFilters = {...this.state.filters, name: this.state.filters.name.trim(), language: this.state.language };
		// Get all keys from filter:
		Object.keys(copyOfFilters)
		// Check for each key what value have:
		.filter(key => this.keyOf(copyOfFilters, key))
		// Append to url query params - key=val. Ex: /indicator?type=input&domain=Health%20Information
		.forEach(key => url.searchParams.append(key, this.keyOf(copyOfFilters, key)));

		// Update the state of the component with already updated URL:
		fetch(url as any)
		.then((data)=>data.json())
		.then((allIndicators)=>{
			this.setState({
				allIndicators,
				pageCount: this.getPageCount(allIndicators),
				indicators: allIndicators.slice(0, pageSize),
				active: 1,
			});
		})
	}

	updateSortValue(value:any) {
		this.setState(
			{filters: {...this.state.filters, sort: value}},
			()=>{ this.filter() }
		);
	}

	keyOf(object:any, key:string){
		return object[key];
	}

	private getPageCount(allIndicators: Indicator[]): number {
		return Math.ceil(allIndicators.length / pageSize);
	}

	handlePageClick(pageNumber: number) {
		this.setState({
			indicators: this.state.allIndicators!.slice(pageSize * (pageNumber-1), pageSize * pageNumber),
			active: pageNumber,
		})
	}

	private i18n (key: string): string {
		return i18n(this.state.language, key);
	}

	render() {
		return <Router>
				{this.state.indicators && (
					<div className="App">
						<Route exact={true} path="/">
							<Navbar sticky={false} language={this.state.language} selectLanguage={(language: LanguageType)=> {
								sessionStorage.setItem("language", language);
								this.setState({
									language,
									filters: {
										name: '',
										type: '',
										domain: '',
										continuum_of_care: '',
										feasible_data_sources: '',
										indicator_level_for_interest_and_use: '',
										sort: ''
									}
								}, ()=> {
									this.filter();
								})
							}}/>
							<NavbarBS>
								<ContainerBS>
									<NavbarBS.Brand>
										<p>
											<span className={'header-title-text'}>
												{/*{this.i18n("Child Health Accountability Tracking (CHAT)")}*/}
												{"Child Health Accountability Tracking (CHAT)"}
											</span><br/>
											{/*<span className={'header-title-text'}>{this.i18n("(MONITOR) -") }</span>*/}
											<span className={'header-title-sub-text'}>{
												// this.i18n("Online indicator Toolkit")
												"Online indicator Toolkit"
											}</span>
										</p>
									</NavbarBS.Brand>
								</ContainerBS>
							</NavbarBS>
							<Header language={this.state.language}/>
							<Filter onFilter={this.filter.bind(this)}
									language={this.state.language}
									savedFilters={this.state.filters}/>
							<Results indicators={this.state.indicators}
									 allIndicatorsLength={this.state.allIndicators!.length}
									 pageCount={this.state.pageCount}
									 active={this.state.active}
									 language={this.state.language}
									 onPageClick={this.handlePageClick.bind(this)}
									 onSortChange={this.updateSortValue.bind(this)}
							/>
						</Route>
						<Route path="/indicator-details/:id"
						render={({match}) =>(
								<div>
									<Navbar sticky={true} language={this.state.language} selectLanguage={(language)=> {
										this.setState({
											language,
										})
									}}/>
									<ViewProject
										language={this.state.language}
										indicator={this.state.allIndicators!
										   .find((indicator: any)=>
											   indicator.id === parseInt(match.params.id)
										   )}
									/>
								</div>
						)}>
						</Route>
						<Route path="/related-links">
							<Navbar sticky={true} language={this.state.language} selectLanguage={(language)=> {
								this.setState({
									language,
								})
							}}/>
							<RelatedLinksView language={this.state.language}/>
						</Route>
						<Footer language={this.state.language}/>
					</div>
				)}
		</Router>
	}
}

export default App;
